<template>
  <v-container>
    <v-card class="px-4 py-4 pb-4">
      <v-card-title class="d-flex align-center">
        <v-icon class="mr-2">mdi mdi-format-list-bulleted</v-icon>
        <span class="font-weight-bold mr-2">Certificación de fondos</span>
      </v-card-title>
      <v-expansion-panels multiple accordion v-if="solicitudNecesidad.data.length > 0"> 
        <v-expansion-panel
          v-for="solicitud in solicitudNecesidad.data"
          :key="solicitud.id"
        >
          <v-expansion-panel-header>
            <b class="text-h6 font-weight-bold" style="line-height: 1.5rem">
              {{ solicitud.correlativo }}
            </b>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panel v-for="fondo in solicitud.fondo" :key="fondo.id">
              <v-expansion-panel-header v-slot:default :hide-actions="noTieneCertificacion(solicitud)">
                <div
                  class="d-flex justify-space-between align-center flex-wrap px-4 py-4"
                  style="gap: 16px"
                >
                  <div class="d-flex align-center flex-wrap" style="gap: 16px">
                    <v-row no-gutters class="align-center">
                      <v-col class="flex-grow-1">
                        <b class="text-h6 font-weight-bold" style="line-height: 1.5rem">
                          {{ `${fondo.cifrado_presupuestario} - (${ fondo.tipo_fondo ? "Votado" : "" })`}}
                        </b>
                        <br />
                        <span class="d-inline-block mt-1">
                          ${{ Number(fondo.monto).toFixed(2) }} disponibles
                        </span>
                      </v-col>
                    </v-row>
                  </div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        class="primary ml-auto"
                        @click.stop="abrirModalDetalle(solicitud, fondo)"
                      >
                        <v-icon class="white--text">mdi-plus</v-icon>
                        Agregar
                      </v-btn>
                    </template>
                    Agregar
                  </v-tooltip>
                </div>
              </v-expansion-panel-header>

              <v-expansion-panel-content
                v-for="unidad in fondo.unidades" :key="unidad.id"
                v-if="unidad.certificaciones.length > 0"
              >
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header v-slot:default class="text-h8">
                      <span class="font-weight-bold">
                        {{ unidad.nombre_unidad }}
                      </span>
                      <div class="d-flex flex-column" style="align-items: end">
                        <span class="text-uppercase">
                          <span class="font-weight-bold"
                            >Monto certificado:</span
                          >
                          ${{ montoCertificado(unidad.certificaciones).toFixed(2) }}
                        </span>
                        <span class="text-uppercase mt-2">
                          <span class="font-weight-bold"
                            >Monto certificado disponibles:</span
                          >
                          ${{Number(unidad.certificaciones[0].cmap.monto).toFixed(2)}}
                        </span>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <DataTableComponent
                        :headers="headers"
                        class="mt-4"
                        :items="unidad.certificaciones"
                        :tiene_paginacion="false"
                      >
                        <template v-slot:item.monto_inicial="{ item }">
                          ${{ Number(item.monto_inicial).toFixed(2) }}
                        </template>

                        <template v-slot:item.fecha_creacion="{ item }">
                          {{
                            moment(item.created_at).format("DD/MM/YYYY")
                          }}
                        </template>
                        <template v-slot:item.documento="{ item }">
                          <v-icon
                            v-if="item.adjunto"
                            small
                            class="ml-1"
                            color="primary"
                            @click.stop="loadAttachment(item.adjunto)"
                          >
                            mdi-file-eye
                          </v-icon>
                        </template>
                      </DataTableComponent>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      
      <EmptyComponent v-else empty colored style="min-width: 100%">
        <template v-slot:empty-content>
          <v-icon x-large>mdi-package-variant-remove </v-icon>
          <p class="mb-0 mt-2 font-weight-bold text-body-1">
            No se encuentran solicitudes de necesidad.
          </p>
        </template>
      </EmptyComponent>
    </v-card>
    <modalCifrado
      :isOpen="abrirModal"
      @close="abrirModal = false"
      :id-fondo="idFondo"
      :id-proceso="idProceso"
      :id-solicitud-necesidad="idSolicitudNecesidad"
      :solicitud="solicitud"
      :fondo="fondo"
    />
    <PdfModal
      :isOpen="isPdfModalOpen"
      :source-loadable="pdfLoadable"
      @on-visibility-change="handlePdfModalVisibility"
      :filename="filename"
    />
  </v-container>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import PdfModal from "@/components/PdfModal.vue";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
} from "@/utils/loadable";
import modalCifrado from "@/views/Cdf/modalCreacionCertificacion.vue";
import { getFilenameFromPath } from "@/utils/data";
import { Observable, Listener } from "@/utils/observable.js";
import EmptyComponent from "@/components/utils/EmptyComponent.vue";

export default {
  components: { modalCifrado, DataTableComponent, PdfModal, EmptyComponent },
  data: () => ({
    name: "certificarFondos",
    headers: [
      { align: "center", sortable: false, text: "Monto Inicial", value: "monto_inicial" },
      { align: "center", sortable: false, text: "Fecha creación", value: "fecha_creacion" },
      { align: "center", sortable: false, text: "Adjunto", value: "documento" },
    ],
    idProceso: null,
    idFondo: null,
    idSolicitudNecesidad: null,
    solicitud: null,
    fondo: null,
    solicitudNecesidad: createLoadable([]),
    pdfLoadable: createLoadable(null),
    abrirModal: false,
    activePath: "",
    filename: "",
    isPdfModalOpen: false,
  }),
  computed: {},
  methods: {
    noTieneCertificacion(solicitud) {
      const certificaciones = solicitud.fondo.flatMap(fondo => fondo.unidades.flatMap(unidad => unidad.certificaciones));
      return certificaciones.length === 0;
    },
    async obtenerSN() {
      toggleLoadable(this.solicitudNecesidad);
      const { data } = await this.services.CertificacionFondo.cifradosCatalogo(
        this.idProceso
      );
      setLoadableResponse(this.solicitudNecesidad, data);
    },
    abrirModalDetalle(solicitud, fondo) {
      this.idFondo = fondo.id;
      this.idSolicitudNecesidad = solicitud.id;
      this.solicitud = solicitud;
      this.fondo = fondo;
      this.abrirModal = true;
    },
    async loadAttachment(path) {
      this.isPdfModalOpen = true;
      if (this.activePath === path) return;

      this.filename = getFilenameFromPath(path);
      this.activePath = path;
      toggleLoadable(this.pdfLoadable);
      const { data } = await this.services.CertificacionFondo.cargarDocumento({
        nombre: path,
      });

      setLoadableResponse(this.pdfLoadable, data, { isFile: true });
    },
    handlePdfModalVisibility(visible) {
      this.isPdfModalOpen = visible;
    },
    montoCertificado(certificacion) {
      const montoTotal = certificacion.reduce(
        (acc, c) => acc + +c.monto_inicial,
        0
      );
      return montoTotal;
    },
  },
  created() {
    this.idProceso = this.$route.params.id_proceso;
    this.obtenerSN();

    const listener = new Listener("certificarFondos").setHandler(() =>
      this.obtenerSN()
    );
    this.unsubscribe = Observable.getInstance().subscribe(
      "recargar-cifrado",
      listener
    );
  },
  beforeDestroy() {
    this.unsubscribe?.();
  },
};
</script>
