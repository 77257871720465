<template>
  <Fragment>
    <v-dialog v-model="isOpen" max-width="1200" persistent>
      <div
        style="
          background-color: #ffffff;
          padding: 0 1em 1em;
          position: relative;
          height: inherit;
        "
      >
        <v-container style="position: relative">
          <div
            class="py-4"
            style="
              width: 100%;
              background-color: inherit;
              position: sticky;
              top: 0;
              z-index: 10;
            "
          >
            <v-btn
              style="
                min-width: unset !important;
                min-height: unset !important;
                width: 32px !important;
                height: 32px !important;
              "
              class="px-4 py-4"
              @click.stop="cerrarModal"
              text
              rounded
            >
              <v-icon>mdi-window-close</v-icon>
            </v-btn>
          </div>
          <div>
            <v-card-title class="d-flex align-center">
              <v-icon class="mr-2">mdi-seal</v-icon>
              <span class="font-weight-bold mr-2">Agregar certificación</span>
            </v-card-title>
          </div>
          <div>
            <v-form ref="form" class="mt-4">
              <v-card style="overflow: hidden">
                <v-col cols="12">
                  <v-row
                    class="flex-column mx-0 my-0"
                    style="text-align: center; gap: 10px; align-items: center"
                  >
                    <v-col
                      cols="12"
                      class="mt-4 px-0 py-0 flex-shrink-1 flex-grow-1"
                    >
                      <v-file-input
                        v-model="adjunto"
                        label="PDF"
                        placeholder="Seleccione un PDF"
                        outlined
                        light
                        accept="application/pdf"
                        :error-messages="adjuntoErrors"
                        @change="$v.adjunto.$touch()"
                        @blur="$v.adjunto.$touch()"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      class="px-0 py-0 flex-shrink-1 flex-grow-1"
                    >
                      <v-autocomplete
                        v-model="form.unidad"
                        label="Unidad"
                        :items="financiamiento.data"
                        item-text="nombre_unidad"
                        return-object
                        :error-messages="unidadErrors"
                        @change="$v.form.unidad.$touch()"
                        @blur="$v.form.unidad.$touch()"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12" class="mt-2 px-0 py-0 d-flex align-center">
                      <v-text-field
                        label="Financiamiento *"
                        v-model="form.monto_financiamiento"
                        outlined
                        style="width: 20%"
                        :disabled="!form.unidad"
                        type="number"
                        :error-messages="montoFinanciamientoErrors"
                        @change="$v.form.monto_financiamiento.$touch()"
                        @blur="$v.form.monto_financiamiento.$touch()"
                      />
                      <div class="pb-6">
                        <v-btn
                          color="secondary"
                          dark
                          class="py-6 ml-2 d-inline-flex align-items-center"
                          @click="agregarFinanciamiento"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-col cols="12">
                    <data-table-component
                      :headers="HEADERS_CONST"
                      :items="listaFinanciamientoItems"
                      inhabilitar_paginacion
                      :tiene_paginacion="false"
                      item-key="row_key"
                    >
                      <template v-slot:[`item.financiamiento`]="{ item }">
                        {{
                          Intl.NumberFormat("en-US", {
                            currency: "USD",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 8,
                          }).format(item.monto_financiamiento)
                        }}
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                              @click="eliminarFinanciamiento(item)"
                            >
                              <v-icon color="secondary">mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          <span> Eliminar </span>
                        </v-tooltip>
                      </template>
                    </data-table-component>

                    <h6 class="text-h6 secondary--text text-end mt-4 mb-0">
                      Total del financiamiento: $
                      {{
                        Intl.NumberFormat("en-US", {
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 8,
                        }).format(this.totalFinanciamiento)
                      }}
                    </h6>
                  </v-col>
                  <div class="d-flex justify-end mt-4">
                    <v-btn
                      class="flex-grow-1 flex-shrink-1"
                      @click.stop="cerrarModal"
                      color="secondary"
                      >Cerrar</v-btn
                    >
                    <v-btn
                      class="flex-grow-1 flex-shrink-1 ml-4"
                      @click="confirmarCertificacion"
                      color="primary"
                      :loading="guardadoCertificacion.isLoading"
                      >Guardar</v-btn
                    >
                  </div>
                </v-col>
              </v-card>
            </v-form>
          </div>
        </v-container>
      </div>
      <ConfirmationModalComponent
        :isOpen="modalConfirmacionAbierta"
        description="¿Desea continuar con la acción?"
        @confirm="guardarCertificacion"
        @cancel="modalConfirmacionAbierta = false"
        :isLoading="guardadoCertificacion.isLoading"
      />
    </v-dialog>
  </Fragment>
</template>

<script>
import { required, maxValue, minValue } from "vuelidate/lib/validators";
import { Validator } from "@/utils/form-validation.js";
import DataTableComponent from "@/components/DataTableComponent.vue";
import { convertToFormData } from "@/utils/data";
import { Observable } from "@/utils/observable.js";
import {
  RetryDataLoading,
  ConfirmationModalComponent,
  ProgresiveLoadAutocomplete,
} from "@/components/utils";
import { Fragment } from "vue-fragment";
import {
  createLoadable,
  toggleLoadable,
  setLoadableResponse,
} from "@/utils/loadable.js";

export default {
  name: "modalCifrado",
  emits: ["on-save"],
  components: {
    RetryDataLoading,
    Fragment,
    ConfirmationModalComponent,
    ProgresiveLoadAutocomplete,
    DataTableComponent,
  },
  props: {
    isOpen: { type: Boolean },
    idFondo: { type: Number },
    idSolicitudNecesidad: { type: Number },
    idProceso: { type: String },
    solicitud: { type: Object },
    fondo: { type: Object },
  },
  validations() {
    return {
      adjunto: { required },
      form: {
        monto_financiamiento: {
          ...(this.listaFinanciamiento.length === 0 && { required }),
          maxValue: maxValue(this.max),
          minValue: minValue(0),
        },
        unidad: {
          ...(this.listaFinanciamiento.length === 0 && {
            required,
          }),
        },
      },
    };
  },
  data: () => ({
    financiamiento: createLoadable([]),
    guardadoCertificacion: createLoadable(null),
    listaFinanciamiento: [],
    totalFinanciamiento: 0,
    modalConfirmacionAbierta: false,
    HEADERS_CONST: [
      { text: "Unidad", value: "nombre_unidad", align: "center" },
      { text: "Financiamiento($)", value: "financiamiento", align: "center" },
      { text: "Acciones", value: "actions", align: "center", width: "25%" },
    ],
    adjunto: null,
    form: {
      monto_financiamiento: null,
      unidad: null,
    },
  }),
  computed: {
    listaFinanciamientoItems() {
      return this.listaFinanciamiento.map((item, key) => ({ ...item, row_key: `${item.id}-${key}` }));
    },
    max() {
      if (!this.solicitud) return 0;
      const unidadEncontrada = this.financiamiento.data.find((unidad) => unidad.id_financiamiento === this.form.unidad?.id_financiamiento );
      const montoDisponible = unidadEncontrada?.certificacion.filter(certificacion => certificacion.id_financiamiento_unidad === this.form.unidad?.id_financiamiento)
      .reduce((acc, certificacion) => {
        return acc + +certificacion.monto_inicial;
      }, 0);

      const diferencia = Number(this.fondo.monto - montoDisponible).toFixed(2);

      return diferencia;
    },

    adjuntoErrors() {
      return new Validator(this.$v.adjunto).detect().getResult();
    },
    montoFinanciamientoErrors() {
      return new Validator(this.$v.form.monto_financiamiento)
        .detect()
        .getResult();
    },
    unidadErrors() {
      return new Validator(this.$v.form.unidad).detect().getResult();
    },
  },
  methods: {
    async listarFinanciamientosUnidad() {
      toggleLoadable(this.financiamiento);
      const { data } =
        await this.services.CertificacionFondo.obtenerFinanciamientoUnidad(
          this.idFondo,
          {
            id_proceso: this.idProceso,
            id_solicitud_necesidad: this.idSolicitudNecesidad,
          }
        );
      setLoadableResponse(this.financiamiento, data);
    },
    agregarFinanciamiento() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      if (this.form.unidad && this.form.monto_financiamiento) {
        const data = {
          id: this.idFondo,
          id_unidad: this.form.unidad.id_unidad,
          nombre_unidad: this.form.unidad.nombre_unidad,
          monto_financiamiento: this.form.monto_financiamiento,
          id_financiamiento: this.form.unidad.id_financiamiento,
        };

        if (+this.form.monto_financiamiento <= 0) {
          this.temporalAlert({
            show: true,
            message:
              "No se puede agregar un financiamiento con el valor de $0.00.",
            type: "warning",
          });
          return;
        }
        
        if (this.solicitud) {
          data.id_obs = this.solicitud.id_obs;
          data.id_solicitud_necesidad = this.solicitud.id;
        }
        //Ver la unidad con la certificacion y el monto disponible que tiene, luego sacamos la diferencia para compararlo con lo que se esta Financiando.
        const unidadEncontrada = this.financiamiento.data.find((unidad) => unidad.id_financiamiento === this.form.unidad?.id_financiamiento );
        const montoDisponible = unidadEncontrada?.certificacion.filter(certificacion => certificacion.id_financiamiento_unidad === this.form.unidad?.id_financiamiento)
        .reduce((acc, certificacion) => {
          return acc + +certificacion.monto_inicial;
        }, 0);

        const diferencia = Number(this.fondo.monto - montoDisponible).toFixed(2);

        const totalPreliminar = this.totalFinanciamiento + Number(this.form.monto_financiamiento)


        if (totalPreliminar > +diferencia) {
          this.temporalAlert({
            show: true,
            message:
              `La cantidad es mayor a la cantidad disponible: $${this.fondo.monto} `,
            type: "warning",
          });
          return;
        }else{
          this.totalFinanciamiento = totalPreliminar;
          this.listaFinanciamiento.push(data);
        }

        this.form = {};
        this.$v.$reset();
      }
    },
    eliminarFinanciamiento(item) {
      const index = this.listaFinanciamiento.findIndex(
        (financiamiento) => financiamiento.id === item.id
      );
      this.totalFinanciamiento -= Number(item.monto_financiamiento);
      this.listaFinanciamiento.splice(index, 1);
    },
    async guardarCertificacion() {
      if (this.listaFinanciamiento.length > 0) {
        toggleLoadable(this.guardadoCertificacion);

        // Enviar un solo financiamiento ejemplo si se cargan 3 de Comunicaciones que vayan juntos asi no crea 3 registros del mismo.
        const financiamientos = this.listaFinanciamiento.reduce((acc, item) => {
          if (!acc[item.id_financiamiento]) {
            acc[item.id_financiamiento] = {
              id_obs: item.id_obs,
              id_solicitud_necesidad: item.id_solicitud_necesidad,
              id_financiamiento: item.id_financiamiento,
              monto_inicial: 0,
            };
          }
          acc[item.id_financiamiento].monto_inicial = Number(
            (
              Number(acc[item.id_financiamiento].monto_inicial) +
              Number(item.monto_financiamiento)
            ).toFixed(2)
          );
          return acc;
        }, {});

        const payload = {
          id_proceso_compra: this.idProceso,
          financiamientos: Object.values(financiamientos),
          adjunto: this.adjunto,
        };

        const fromData = convertToFormData(payload);
        const { data } =
          await this.services.CertificacionFondo.crearCerticacion(fromData);

        setLoadableResponse(this.guardadoCertificacion, data, {
          showAlertOnSuccess: true,
        });

        this.modalConfirmacionAbierta = false;
        this.cerrarModal();
        this.limpiarFormulario();
        this.listaFinanciamiento = [];
        this.totalFinanciamiento = 0;

        Observable.emit("recargar-cifrado");
      } else {
        this.temporalAlert({
          show: true,
          message: "Debe agregar al menos un financiamiento.",
          type: "warning",
        });
      }
    },

    confirmarCertificacion() {
      this.$v.$touch();

      if (this.$v.$invalid) return;

      if (this.listaFinanciamiento.length === 0) {
        this.temporalAlert({
          show: true,
          message: "Debe agregar al menos un financiamiento.",
          type: "warning",
        });
      } else {
        this.modalConfirmacionAbierta = true;
      }
    },
    limpiarFormulario() {
      this.form = {};
      this.adjunto = null;
      this.totalFinanciamiento = 0;
      this.listaFinanciamiento = [];
      this.$v.$reset();
    },
    cerrarModal() {
      this.limpiarFormulario();
      this.$emit("close");
    },
  },
  watch: {
    isOpen(newVal) {
      if (newVal && this.idFondo) {
        this.listarFinanciamientosUnidad();
      }
    },
  },
};
</script>
